import Cookie from 'js.cookie';
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import fullScreen from 'lightgallery/plugins/fullscreen'

export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    lightGallery(document.getElementById('project-gallery'), {
      licenseKey: 'F28B4CA0-283749F1-BBEB75B9-915E2B0B',
      selector: '.gallery-image',
      plugins: [lgZoom, lgThumbnail, fullScreen],
      speed: 500,
      fullScreen: true,
      mobileSettings: {
        controls: true,
        showCloseIcon: true,
        download: false,
      },
    });

    // toggles hamburger and nav open and closed states
    var removeClass = true;
    $('.hamburger').click(function () {
      $('.hamburger').toggleClass('is-active');
      $('#sideNav').toggleClass('sideNav-open');
      $('.sideNavBody').toggleClass('sideNavBody-push');
      removeClass = false;
    });

    $('.sideNav').click(function() {
      removeClass = false;
    });

    document.addEventListener('touchstart', function(e) {
      if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
        $('.hamburger').removeClass('is-active');
        $('#sideNav').removeClass('sideNav-open');
        $('.sideNavBody').removeClass('sideNavBody-push');
      }
      removeClass = true;
    }, false);
    
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.scrollup').fadeIn();
      } else {
        $('.scrollup').fadeOut();
      }
    });

    if (!Cookie.get('alert') && $('#modal').length) {
      $('#modal').delay(1100).fadeIn(700);
    }
    
    $('.modal .btn-close').click(function() {
      $('#modal').hide();
      Cookie.set('alert', true, {
        expires: 1,
      });
    });
    
    $('.scrollup').click(function () {
      $('html, body').animate({
        scrollTop: 0,
      }, 600);
      return false;
    });

    //dropdown on click
    $('.banner nav li.menu-item-has-children > a').click(function(e) {
      e.preventDefault();
      $(this).parent('.banner nav li.menu-item-has-children').toggleClass('open');
      $(this).parent('.banner nav li.menu-item-has-children').siblings('.open').removeClass('open');
    });

    //dropdown on click sideNav
    $('.banner .sideNav .nav li.menu-item-has-children > a').click(function(e) {
      e.preventDefault();
      $(this).parent('.banner .sideNav .nav li.menu-item-has-children').toggleClass('open');
      $(this).parent('.banner .sideNav .nav li.menu-item-has-children').siblings('.open').removeClass('open');
    });
    
    //close dropdown when clicking anywhere else
    $(document).on('click touch', function(e) {
      if( !$(e.target).hasClass('.banner nav li.menu-item-has-children') && !$(e.target).parents('.banner nav li.menu-item-has-children').length ) {
        $('.banner nav li.menu-item-has-children.open').removeClass('open');
      }
    });
  
    //sidenav dropdown on click
    $('.sideNav li.menu-item-has-children > a').click(function(e){
      e.preventDefault();
      $(this).next('.sideNav .sub-menu').slideToggle();
      $(this).parent('.sideNav li.menu-item-has-children').toggleClass('open');
    });

    $('.sideNav .sub-menu a').click(function(){
      $('.hamburger').removeClass('is-active');
      $('#sideNav').removeClass('sideNav-open');
      $('.sideNavBody').removeClass('sideNavBody-push');
    });
 
     $(window).scroll(function () {
       if ($(this).scrollTop() > 100) {
         $('.scrollup').fadeIn();
       } else {
         $('.scrollup').fadeOut();
       }
     });

    if (!Cookie.get('alert') && $('#modal').length) {
      $('#modal').show();
    }
    
    $('.modal .btn-close').click(function() {
      $('#modal').hide();
      Cookie.set('alert', true, {
        expires: 1,
      });
    });
    
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.scrollup').fadeIn();
      } else {
        $('.scrollup').fadeOut();
      }
    });
    
    $('.scrollup').click(function () {
      $('html, body').animate({
        scrollTop: 0,
      }, 600);
      return false;
    });

    if ($('.testimonials-carousel').length) {
      $('.testimonials-carousel').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        appendArrows: '.testimonials-panel .slider-nav',
        appendDots: '.testimonials-panel .slider-nav',
        adaptiveHeight: true,
        arrows: true,
        dots: true,
        responsive: [
          {
            breakpoint: 1439,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: true,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
      });
    }
    
    const accordionContent = document.querySelectorAll('.accordion-content');
    // Adds .open //
    accordionContent.forEach((item, index) => {
      let header = item.querySelector('.header');
      header.addEventListener('click', () => {
        item.classList.toggle('open');
    
        // Height Description and Change Icon //
        let description = item.querySelector('.description');
        if (item.classList.contains('open')) {
          description.style.height = `${description.scrollHeight}px`;
          item.querySelector('i').classList.replace('fa-plus', 'fa-minus');
        } else {
          description.style.height = '0px';
          item.querySelector('i').classList.replace('fa-minus', 'fa-plus');
        }
        removeOpen(index);
      });
    });
    
    function removeOpen(index1) {
      accordionContent.forEach((item2, index2) => {
        if (index1 != index2) {
          item2.classList.remove('open');
          let descr = item2.querySelector('.description');
          descr.style.height = '0px';
          item2.querySelector('i').classList.replace('fa-minus', 'fa-plus');
        }
      });
    }
    
    $('.moreless-button').click(function() {
      $('.moretext').slideToggle();
      if ($('.moreless-button').text() == 'Read more') {
        $(this).text('Read less')
      } else {
        $(this).text('Read more')
      }
    });
    
    //review us page
  $('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
    localStorage.setItem('activeTab', $(e.target).attr('href'));
    });
    var activeTab = localStorage.getItem('activeTab');
    if (activeTab) {
    $('#review-tab a[href="' + activeTab + '"]').tab('show');
    }
  },
};
